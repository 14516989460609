<template>
  <el-dialog
    :title="titleMsg"
    :close-on-click-modal="false"
    :visible.sync="visible"
    :append-to-body="true">
    <el-form v-loading="formLoading" :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
    <el-form-item label="上级分组" prop="parentName" v-if="dataForm.parentId != '0'">
      <el-input v-model="dataForm.parentName" placeholder="上级分组" readonly></el-input>
    </el-form-item>
    <el-form-item label="分组名称" prop="name">
      <el-input v-model="dataForm.name" placeholder="分组名称"></el-input>
    </el-form-item>
    <el-form-item label="备注" prop="remark">
      <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" :loading="isOnSubmit">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :loading="formLoading || isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { saveOrUpdate } from '@/api/hbi/datacategory.js'
  export default {
    data () {
      return {
        visible: false,
        isOnSubmit: false,
        formLoading: false,
        titleMsg:'',
        dataForm: {
          id: null,
          parentId: '',
          parentName: '',
          name: '',
          remark: '',
        },
        dataRule: {
          name: [{ required: true, message: '分组名称不能为空', trigger: 'blur' }]
        }
      }
    },
    methods: {
      /** 新增节点 */
      addNode(data){
        this.visible = true
        if(data){
          this.titleMsg = '追加子级分组信息'
          this.dataForm = {
            id: null,
            parentId: data.id,
            parentName: data.name,
            name: '',
            remark: '',
          }
        }else{
          this.titleMsg = '新增主分组信息'
          this.dataForm = {
            id: null,
            parentId: '0',
            name: '',
            remark: '',
          }
        }
        this.$nextTick(()=>{
          this.$refs.dataForm.clearValidate()
        })
      },
      /** 修改节点 */
      modifyNode (data) {
        this.titleMsg = '修改分组信息'
        this.visible = true
        this.isOnSubmit = false
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          for(let item in this.dataForm){
            if(data[item] || data[item] === 0){
              this.dataForm[item] = data[item] + ''
            }else{
              this.dataForm[item] = ''
            }
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.isOnSubmit = true
            const data_ = JSON.parse(JSON.stringify(this.dataForm))
            saveOrUpdate(data_).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 500
                })
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                this.$message.error(data.msg)
              }
            }).catch((err) => {
              this.$message.error(err)
            }).finally(()=>{
              this.isOnSubmit = false
            })
          }
        })
      }
    }
  }
</script>
