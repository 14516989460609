<template>
  <div class="basic-container">
    <el-row :gutter="20">
      <el-col :span="10">
        <el-form :inline="true" :model="treeForm" @submit.native.prevent>
          <table class="detailTable_search" style="width: 100%">
            <tr>
              <td class="titleTd width80px">分组名称</td>
              <td class="valueTd">
                <el-input v-model="treeForm.name" placeholder="使用分组名称关键字过滤列表，字母不区分大小写" clearable></el-input>
              </td>
            </tr>
          </table>
        </el-form>
        <div class="wxts_msg_search" >
          <span>分组信息</span>
          <div class="f_t">
            <el-button-group>
              <el-button @click="getTreeList()" :loading="treeLoading">刷新</el-button>
              <el-button v-if="isAuth('hbi:hbidc:save')" type="primary" @click="addOrUpdateHandle()">新增主分组</el-button>
            </el-button-group>
          </div>
        </div>
        <div class="data_tree" >
          <el-row :gutter="20">
            <el-col >
              <el-tree
                :data="dataTree"
                node-key="id"
                ref="dataTree"
                :filter-node-method="filterNode"
                @current-change="treeNodeChange"
                default-expand-all
                :expand-on-click-node="false"
                :props="{label:'name', children:'children'}"
                v-loading="treeLoading">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span>{{ node.label }}</span>
                  <span>
                    <el-button type="text" size="mini" @click="modifyNode(node, data)">修改</el-button>
                    <el-button type="text" size="mini" @click="removeNode(data)">删除分组</el-button>
                    <el-button type="text" size="mini" @click="addOrUpdateHandle(data)">追加子分组</el-button>
                  </span>
                </span>
              </el-tree>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="14">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="currentChangeHandle(1)" @submit.native.prevent>
          <table class="detailTable_search" style="width: 100%">
            <tr>
              <td class="titleTd width80px">模型名称</td>
              <td class="valueTd">
                <el-input v-model="dataForm.name" placeholder="模型名称" clearable></el-input>
              </td>
              <td class="titleTd width80px">模型类型</td>
              <td class="valueTd">
                <sysDictSelect v-model="dataForm.dmType" dictType="data_model_type" placeholder="请选择模型类型"/>
              </td>
            </tr>
          </table>
        </el-form>
        <div class="wxts_msg_search" >
          <span>模型列表{{categoryName ? ('('+categoryName+')') : ''}}</span>
          <div class="f_t">
            <el-button-group>
              <el-button @click="currentChangeHandle(1)" :loading="dataListLoading">查询</el-button>
              <el-popover
                placement="bottom"
                width="180"
                trigger="manual"
                v-model="visible"
                v-if="isAuth('hbi:hbidm:save')">
                <p>请选择新增的模型类型</p>
                <el-cascader-panel
                  v-model="chooseForm.dmType"
                  @change="addHandle"
                  :options="cascaderOptions"
                  :props="{ label: 'name', value: 'value' }">
                </el-cascader-panel>
                <el-button v-if="isAuth('hbi:hbidm:save')" type="primary" slot="reference" @click="openPopover">新增</el-button>
              </el-popover>
              <!-- <el-button v-if="isAuth('hbi:hbidm:save')" type="primary" @click="addHandle()">新增</el-button> -->
            </el-button-group>
          </div>
        </div>
        <el-table :data="dataList" border stripe v-loading="dataListLoading" style="width: 100%;">
          <el-table-column prop="name" header-align="center" align="center" label="模型名称"></el-table-column>
          <el-table-column prop="dmType" header-align="center" align="center" label="模型类型">
            <template slot-scope="scope">
              <sysDict type="data_model_type" :value="scope.row.dmType"/>
            </template>
          </el-table-column>
          <el-table-column prop="tableName" header-align="center" align="center" label="表/视图名称"></el-table-column>
          <el-table-column prop="sqlText" header-align="center" align="center" label="sql语句"></el-table-column>
          <el-table-column prop="remark" header-align="center" align="center" label="备注"></el-table-column>
          <el-table-column header-align="center" align="center" width="120" label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" v-if="isAuth('hbi:hbidm:update')" @click="updateHandle(scope.row)">修改</el-button>
              <el-button type="text" size="small" v-if="isAuth('hbi:hbidm:delete')" @click="deleteHandle(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalCount"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </el-col>
    </el-row>
    <el-dialog
      title="选择数据源"
      :close-on-click-modal="false"
      :visible.sync="visible_ds"
      :append-to-body="true"
      width="80%">
      <el-form :inline="true" :model="chooseForm" @keyup.enter.native="getTables()" @submit.native.prevent>
        <table class="detailTable_search" style="width: 100%">
          <tr>
            <td class="titleTd width80px">模型类型</td>
            <td class="valueTd">
              <sysDictSelect v-model="chooseForm.dmType" dictType="data_model_type" placeholder="请选择模型类型" disabled/>
            </td>
            <td class="titleTd width80px">数据源</td>
            <td class="valueTd">
              <el-select v-model="chooseForm.dsId" @change="getTables" filterable placeholder="请选择数据源" style="width:100%">
                <el-tooltip v-for="item in dsOptions" :key="item.id" placement="top-start" :open-delay="300" :enterable="false">
                  <div slot="content">
                    类型：{{ item.dsType }}<br/>
                    名称：{{ item.dsDatabase }}<br/>
                    地址：{{ item.dsIp }}:{{ item.dsPort }}<br/>
                    用户：{{ item.dsUser }}<br/>
                  </div>
                  <el-option :label="item.name" :value="item.id"></el-option>
                </el-tooltip>
              </el-select>
            </td>
          </tr>
        </table>
      </el-form>
      <div class="wxts_msg_search" v-if="showTabel()">
        <span>请在选择数据源后，根据实际需求选择需要设置的表</span>
        <div class="f_t">
          <el-button-group>
            <el-button @click="getTables()" :loading="tableListLoading">查询</el-button>
          </el-button-group>
        </div>
      </div>
      <div class="show_table" v-if="showTabel()" style="width:100%;min-height:50px;max-height: 500px;overflow-y: auto;" v-loading="tableListLoading">
        <el-radio-group v-model="chooseForm.table" class="list">
          <el-row :span="24">
            <el-col v-for="(item,index) in tableList" :key="index" :md="8" :xs="12" :sm="12">
              <el-tooltip class="item" effect="dark" placement="top-start" :open-delay="150">
                <div slot="content">
                  <p>表名：{{ item.tableName }}</p>
                  <p v-if="item.tableComment">注释：{{ item.tableComment }}</p>
                </div>
                <el-radio :label="item.tableName">{{item.tableName}}{{item.tableComment ? ("(" + item.tableComment + ")"):""}}</el-radio>
              </el-tooltip>
            </el-col>
          </el-row>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible_ds = false">取消</el-button>
        <el-button type="primary" @click="chooseFormSubmit('addOrUpdate', 'addOrUpdateVisible')">确定</el-button>
      </span>
    </el-dialog>
    <!-- 弹窗, 新增 / 修改 模型信息-数据库类型（表、视图、sql） -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!-- 弹窗, 新增 / 修改 模型信息-文件类型（excel、csv） -->
    <edit-file-model v-if="editFileModelVisible" ref="editFileModel" @refreshDataList="getDataList"></edit-file-model>
    <!-- 弹窗, 新增 / 修改 分组信息 -->
    <add-or-update-tree v-if="addOrUpdateTreeVisible" ref="addOrUpdateTree" @refreshDataList="getTreeList"></add-or-update-tree>
  </div>
</template>

<script>
import AddOrUpdate from './add-or-update'
import EditFileModel from './edit-file-model'
import AddOrUpdateTree from './add-or-update-tree'
import { getList, deleteData, getTablesById } from '@/api/hbi/datamodel.js'
import { getAllDsList } from '@/api/hbi/dynamicdatasource.js'
import { getAllList, deleteData as deleteTreeData } from '@/api/hbi/datacategory.js'
import { treeDataTranslate } from '@/utils/index.js'
import { getDictTypeValues } from '@/api/sys/dict'
export default {
  data () {
    return {
      treeForm:{
        name:'',
      },
      dataForm: {
        name:'',
        dmType: ''
      },
      cascaderOptions: [],
      dataTree: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      visible: false,
      treeLoading: false,
      dataListLoading: false,
      tableListLoading: false,
      addOrUpdateVisible: false,
      editFileModelVisible: false,
      addOrUpdateTreeVisible: false,
      visiblePopover: false,
      visible_ds: false,
      dsOptions:[],//数据源
      tableList:[],//指定数据源的表信息
      chooseForm:{
        dsId:'',
        dmType:'',
        table:''
      },
      categoryId:'',// 当前选择的分组id
      categoryName:'',// 当前选择的分组名称
    }
  },
  components: {
    AddOrUpdate, AddOrUpdateTree, EditFileModel
  },
  created() {
    this.getCascaderOptions()
  },
  activated () {
    this.getTreeList();
    if(!this.cascaderOptions || this.cascaderOptions.length == 0){
      this.getCascaderOptions()
    }
  },
  watch: {
    'treeForm.name'(val) {
      this.$refs.dataTree.filter(val);
    }
  },
  methods: {
    /** 获取模型类型的字典信息 */
    getCascaderOptions(){
      getDictTypeValues("data_model_type").then((list) => {
        this.cascaderOptions = list
      }).catch((err) => {
        this.$message.error(err)
      })
    },
    /** 获取数据模型列表 */
    getDataList () {
      this.dataListLoading = true
      const params_ = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'name': this.dataForm.name,
        'dmType': this.dataForm.dmType,
        'categoryId': this.categoryId
      }
      getList(params_).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list
          this.totalCount = data.data.totalCount
        } else {
          this.dataList = []
          this.totalCount = 0
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.dataListLoading = false
      })
    },
    /** 每页数 */
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    /** 当前页 */
    currentChangeHandle (val) {
      if(!this.categoryId){
        this.$message.error('请先选择模型分组')
        return
      }
      this.pageIndex = val
      this.getDataList()
    },
    openPopover(){
      if(!this.categoryId){
        this.$message.error('请先选择新增模型的分组')
        return
      }
      this.chooseForm = {
        dsId: '',
        table: '',
        dmType: ''
      }
      this.tableList = []
      this.visible = !this.visible
    },
    /** 新增模型 */
    addHandle (val) {
      this.visible = false
      if(!this.categoryId){
        this.$message.error('请先选择分组再新增模型信息')
        return
      }
      if(val == 'table' || val == 'view' || val == 'sql'){
        this.visible_ds = true
        this.getDsOptions()
      }else{
        this.chooseFormSubmit('editFileModel', 'editFileModelVisible')
      }
    },
    /** 修改模型 */
    updateHandle (row) {
      if(row.dmType == 'table' || row.dmType == 'view' || row.dmType == 'sql'){
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(row.id)
        })
      }else{
        this.editFileModelVisible = true
        this.$nextTick(() => {
          this.$refs.editFileModel.init(row.id)
        })
      }
    },
    /** 删除模型 */
    deleteHandle (row) {
      this.$confirm(`确定对模型[${row.name}]进行删除操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListLoading = true
        deleteData([row.id]).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message.success('操作成功')
            this.getDataList()
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.dataListLoading = false
        })
      }).catch(() => { })
    },
    /** 获取数据源信息 */
    getDsOptions () {
      getAllDsList().then(({ data }) => {
        if (data && data.code === 0) {
          this.dsOptions = data.data
        } else {
          this.dsOptions = []
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {})
    },
    /** 当数据源变更时根据数据源id查询数据源指定库中表信息 */
    getTables(val){
      if(!this.showTabel()){
        return
      }
      this.tableListLoading = true
      let id = val ? val : this.chooseForm.dsId;
      getTablesById(id).then(({ data }) => {
        if (data && data.code === 0) {
          this.tableList = data.data
        } else {
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.tableListLoading = false
      })
    },
    /** 选择表信息后打开编辑页面 */
    chooseFormSubmit(refName, visibleName){
      let dsId = this.chooseForm.dsId
      let table = this.chooseForm.table
      let dmType = typeof this.chooseForm.dmType == 'string' ? this.chooseForm.dmType : this.chooseForm.dmType[this.chooseForm.dmType.length-1]
      if(/^((table)|(view)|(sql))$/.test(dmType)){
        if(!dsId){
          this.$message.error("请选择数据源")
          return
        }
        if(this.showTabel() && !table){
          this.$message.error("请选择表信息")
          return
        }
        this.visible_ds = false
      }
      this[visibleName] = true
      this.$nextTick(() => {
        this.$refs[refName].initAdd(dsId, table, dmType, this.categoryId)
      })
    },
    /** 是否需要（展示）表信息 */
    showTabel(){
      let type = this.chooseForm.dmType
      if(/^((table)|(view))$/.test(type)){
        return true
      }
      return false
    },

    /////////////////////////////////////////////////////
    ////////////////数据模型分组相关方法///////////////////
    /////////////////////////////////////////////////////

    /** 查询分组信息并转化为树型结构 */
    getTreeList () {
      this.treeLoading = true
      getAllList().then(({ data }) => {
        if (data && data.code === 0) {
          const list = data.data
          this.dataTree = list && list.length > 0 ? treeDataTranslate(list, 'id', 'parentId') : [];
          this.treeForm.name = ''
          this.categoryId = ''
          this.categoryName = ''
          this.dataList = []
          this.totalCount = 0
        } else {
          this.dataTree = []
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.treeLoading = false
      })
    },
    /** 修改分组节点 */
    modifyNode(node, data){
      let pName = node.parent.data.name
      this.$set(data, 'parentName', pName);
      this.addOrUpdateTreeVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdateTree.modifyNode(data)
      })
    },
    /** 添加分组节点 */
    addOrUpdateHandle(data){
      this.addOrUpdateTreeVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdateTree.addNode(data)
      })
    },
    /** 删除分组节点 */
    removeNode(data){
      this.$confirm(`确定对分组【${data.name}】进行删除操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.treeLoading = true
        deleteTreeData([data.id]).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message.success('操作成功')
            this.getTreeList()
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.treeLoading = false
        })
      }).catch(() => { })
    },
    /** 树信息过滤 */
    filterDataList(){
      this.$refs.dataTree.filter(this.treeForm.name);
    },
    /** 树信息过滤实现 */
    filterNode(value, data) {
      if (!value) return true;
      let v = value.toLowerCase()
      let d = data.name.toLowerCase()
      return d.indexOf(v) !== -1;
    },
    /** 选中分组节点变化时加载分组下模型信息列表 */
    treeNodeChange(val){
      this.categoryId = val.id
      this.categoryName = val.name
      //根据分组ID查询配置列表的数据
      this.currentChangeHandle(1);
    }
  }
}
</script>
<style scoped>
.data_tree span.custom-tree-node{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.show_table .el-col{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>