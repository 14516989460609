<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    :append-to-body="true"
    width="90%">
    <el-form v-loading="formLoading" :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px">
      <!-- <el-divider content-position="left">模型基本信息</el-divider> -->
      <div class="wxts_msg_search" >
        <span>模型基本信息</span>
      </div>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="模型分组" prop="categoryId">
            <el-cascader
              v-model="dataForm.categoryId"
              :options="categoryOptions"
              :show-all-levels="true"
              placeholder="请选择模型分组"
              style="width:100%"
              :props="{ checkStrictly: true, value: 'id', label: 'name' }"
              filterable
              disabled
              :filter-method="filterNode">
              <template slot-scope="{ node, data }">
                <span>{{ data.name }}</span>
                <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
              </template>
            </el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="数据源" prop="dsId">
            <el-select v-model="dataForm.dsId" filterable placeholder="请选择数据源" style="width:100%" disabled>
              <el-tooltip v-for="item in dsOptions" :key="item.id" placement="top-start" :open-delay="300" :enterable="false">
                <div slot="content">
                  类型：{{ item.dsType }}<br/>
                  名称：{{ item.dsDatabase }}<br/>
                  地址：{{ item.dsIp }}:{{ item.dsPort }}<br/>
                  用户：{{ item.dsUser }}<br/>
                </div>
                <el-option :label="item.name" :value="item.id"></el-option>
              </el-tooltip>
              <!-- <el-option
                v-for="item in dsOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option> -->
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="模型名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="模型名称" maxlength="100" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="模型类型" prop="dmType">
            <sysDictSelect v-model="dataForm.dmType" dictType="data_model_type" placeholder="请选择模型类型" disabled/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="表/视图名称" prop="tableName" v-if="dataForm.dmType == 'table' || dataForm.dmType == 'view'">
        <el-input v-model="dataForm.tableName" placeholder="表/视图名称" disabled></el-input>
      </el-form-item>
      <el-form-item label="sql语句" prop="sqlText" v-if="dataForm.dmType == 'sql'">
        <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 6}"
          v-model="dataForm.sqlText"
          placeholder="sql语句"
          maxlength="4000"
          show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea"
          :autosize="{ minRows: 3, maxRows: 6}"
          v-model="dataForm.remark"
          placeholder="备注"
          maxlength="1000"
          show-word-limit>
        </el-input>
      </el-form-item>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="字段管理" name="columnManage">
          <div class="wxts_msg_search" >
            <span>模型字段信息</span>
            <div class="f_t">
              <el-button-group>
                <el-button type="success" @click="analysisSql()" v-if="dataForm.dmType == 'sql'">解析sql</el-button>
                <el-button type="success" @click="asyncColumn()" v-if="dataForm.dmType == 'table' || dataForm.dmType == 'view'">同步字段</el-button>
                <el-button type="primary" @click="addVirtualColumn()">新增虚拟字段</el-button>
              </el-button-group>
            </div>
          </div>
          <el-table :data="dataForm.columns" border stripe style="width: 100%;" v-loading="dataListLoading" :max-height="450">
            <el-table-column prop="customColumnName" header-align="center" align="center" label="字段名称" width="180">
              <template slot-scope="scope">
                <el-input v-model="scope.row.customColumnName" placeholder="字段名称" maxlength="100" show-word-limit></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="columnName" header-align="center" align="center" label="原始字段名称" width="180"></el-table-column>
            <el-table-column prop="customColumnType" header-align="center" align="center" label="字段类型" width="130">
              <template slot-scope="scope">
                <sysDictSelect v-model="scope.row.customColumnType" dictType="column_type" placeholder="请选择字段类型" filterable/>
              </template>
            </el-table-column>
            <el-table-column prop="columnType" header-align="center" align="center" label="原始类型" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.virtualColumn == 1">/</span>
                <span>{{scope.row.columnType}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="columnLength" header-align="center" align="center" label="字段长度" width="80"></el-table-column>
            <el-table-column prop="columnRemark" header-align="center" align="center" label="中文备注">
              <template slot-scope="scope">
                <el-input v-model="scope.row.columnRemark" placeholder="字段名称" maxlength="500" show-word-limit></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="virtualColumn" header-align="center" align="center" label="是否虚拟字段" width="100">
              <template slot-scope="scope">
                <sysDict type="yes_or_no" :value="scope.row.virtualColumn"/>
              </template>
            </el-table-column>
            <el-table-column prop="virtualType" header-align="center" align="center" label="虚拟字段填充模式" width="120">
              <template slot-scope="scope">
                <sysDictSelect :clearable="false" v-if="scope.row.virtualColumn == 1" v-model="scope.row.virtualType" dictType="virtual_type" placeholder="虚拟字段填充模式" filterable/>
                <span v-else>/</span>
              </template>
            </el-table-column>
            <el-table-column prop="virtualContent" header-align="center" align="center" label="虚拟字段填充内容">
              <template slot-scope="scope">
                <el-input type="textarea"
                  v-if="scope.row.virtualColumn == 1"
                  :autosize="{ minRows: 1, maxRows: 6}"
                  v-model="scope.row.virtualContent"
                  placeholder="虚拟字段填充内容"
                  maxlength="500"
                  show-word-limit>
                </el-input>
                <span v-else>/</span>
              </template>
            </el-table-column>
            <el-table-column header-align="center" align="center" width="90" label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="deleteHandle(scope)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="数据预览" name="datePreview">
          <div class="wxts_msg_search" >
            <span>预览数据默认显示10条</span>
            <div class="f_t">
              <el-button-group>
                <el-button type="success" @click="getPreviewList()">刷新</el-button>
              </el-button-group>
            </div>
          </div>
          <el-table :data="previewList" border stripe v-loading="previewListLoading" style="width: 100%;" :max-height="450">
            <template v-for="(item, index) in dataForm.columns">
              <el-table-column
                :key="index"
                :prop="item.customColumnName"
                header-align="center"
                align="center"
                show-overflow-tooltip
                :label="item.columnRemark ? item.columnRemark : item.customColumnName">
                <template slot-scope="scope">
                  {{showColInfo(item.customColumnName, scope.row)}}
                </template>
                </el-table-column>
            </template>
          </el-table>
          <!-- <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="totalCount"
            layout="total, sizes, prev, pager, next, jumper">
          </el-pagination> -->
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" :loading="isOnSubmit">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :loading="formLoading || isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAllById, saveOrUpdate, getColumnsByDsIdAndTanleName, getPreviewList, analysisSql } from '@/api/hbi/datamodel.js'
import { getAllList } from '@/api/hbi/datacategory.js'
import { getAllDsList } from '@/api/hbi/dynamicdatasource.js'
import { treeDataTranslate } from '@/utils/index.js'
import { encryptDes } from "@/utils/des"
export default {
  data () {
    const validateTableName = (rule, value, callback) => {
      if (!rule.required && !value) {
        callback()
      } else {
        if (!value || value.length == 0) {
          callback(new Error(`表/视图名称不能为空`))
        } else if (!/^([A-Za-z]+[a-zA-Z0-9_]*\.)?([A-Za-z]+[a-zA-Z0-9_]*)$/.test(value)) {
          callback(new Error(`表/视图名称格式不正确`))
        } else {
          callback()
        }
      }
    };
    return {
      activeName: 'columnManage', // 当前选择的tab
      visible: false, // 页面显示标记
      isOnSubmit: false, // 提交状态
      formLoading: false, // 表单加载状态
      dataListLoading: false, // 字段信息加载状态
      previewListLoading: false, // 预览数据加载状态
      dsOptions: [], // 数据源列表
      categoryOptions: [], // 模型分组树形列表
      previewList: [], // 预览列表
      dataForm: {
        id: null,
        categoryId: '',
        name: '',
        dmType: '',
        dsId: '',
        tableName: '',
        sqlText: '',
        version: '',
        deleteFlag: '',
        remark: '',
        columns:[]
      },
      dataRule: {
        categoryId: [{ required: true, message: '请选择模型分组', trigger: ['blur','change'] }],
        dsId: [{ required: true, message: '请选择数据源', trigger: ['blur','change'] }],
        name: [{ required: true, message: '模型名称不能为空', trigger: 'blur' }],
        dmType: [{ required: true, message: '请选择模型类型 ', trigger: ['blur','change'] }],
        tableName: [{ required: true, validator: validateTableName, trigger: 'blur' }],
        sqlText: [{ required: true, message: 'sql语句不能为空', trigger: 'blur' }]
      },
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
    }
  },
  created() {
    this.getCategoryOptions()
    this.getDsOptions()
  },
  methods: {
    /** 修改模型 */
    init (id) {
      this.dataForm.id = id
      this.visible = true
      this.previewList = []
      this.activeName = 'columnManage'
      this.isOnSubmit = false
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          this.formLoading = true
          getAllById(id).then(({ data }) => {
            if (data && data.code === 0) {
              let res = data.data
              for (let item in this.dataForm) {
                if (res[item] || res[item] === 0) {
                  if(item == 'columns'){
                    this.dataForm[item] = res[item]
                  }else{
                    this.dataForm[item] = res[item] + ''
                  }
                } else {
                  if(item == 'columns'){
                    this.dataForm[item] = []
                  }else{
                    this.dataForm[item] = ''
                  }
                }
              }
            }
          }).catch((err) => {
            this.$message.error(err)
          }).finally(() => {
            this.formLoading = false
          })
        }
      })
    },
    /** 新增模型 */
    initAdd(dsId, table, dmType, categoryId){
      // 初始化页面
      this.visible = true
      this.previewList = []
      this.activeName = 'columnManage'
      for (let item in this.dataForm) {
        if(item == 'columns'){
          this.dataForm[item] = []
        }else{
          this.dataForm[item] = ''
        }
      }
      this.dataForm.dmType = dmType
      this.dataForm.dsId = dsId
      this.dataForm.tableName = table
      this.dataForm.categoryId = categoryId
      this.$nextTick(()=>{
        this.$refs.dataForm.clearValidate()
      })
      if(/^((table)|(view))$/.test(dmType)){
        this.getColumns(dsId, table)
      }
    },
    /** 加载表字段信息 */
    getColumns(dsId, table){
      //this.dataForm.columns
      const params_ = {
        'dsId': dsId,
        'tableName': table
      }
      this.dataListLoading = true
      getColumnsByDsIdAndTanleName(params_).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataForm.columns = data.data
        } else {
          this.dataForm.columns = []
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.dataListLoading = false
      })
    },
    /** 同步字段 */
    asyncColumn(){
      this.$confirm(`同步字段将重新获取配置的数据库字段信息，已经添加的虚拟字段会保留，确定执行同步操作？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params_ = {
          'dsId': this.dataForm.dsId,
          'tableName': this.dataForm.tableName
        }
        this.dataListLoading = true
        getColumnsByDsIdAndTanleName(params_).then(({ data }) => {
          if (data && data.code === 0) {
            let oldColumns = JSON.parse(JSON.stringify(this.dataForm.columns))
            let newColumns = data.data
            for(let item of oldColumns){
              if(item.virtualColumn == 1){
                newColumns.push(item)
              }
            }
            this.dataForm.columns = newColumns
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.dataListLoading = false
        })
      }).catch(() => { })
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.isOnSubmit = true
          const data_ = JSON.parse(JSON.stringify(this.dataForm))
          // 修改时，如果没有修改categoryId则该属性为字符串无需提取选择的id
          data_.categoryId = typeof data_.categoryId == 'string' ? data_.categoryId : data_.categoryId[data_.categoryId.length-1]
          saveOrUpdate(data_).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message.success('操作成功')
              this.visible = false
              this.$emit('refreshDataList')
            } else {
              this.$message.error(data.msg)
            }
          }).catch((err) => {
            this.$message.error(err)
          }).finally(() => {
            this.isOnSubmit = false
          })
        }
      })
    },
    /** 新增或者修改字段信息 */
    addVirtualColumn(){
      this.dataForm.columns.push({
        columnName: '',
        customColumnName: '',
        customColumnType: '',
        columnType: '',
        columnLength: '',
        columnRemark: '',
        virtualColumn: 1,
        virtualType: '1',
        virtualContent: '',
      })
    },
    /** 解析sql */
    analysisSql(){
      if(this.dataForm.columns && this.dataForm.columns.length > 0){
        this.$confirm(`当前模型字段已经存在，重新解析后将会替换当前字段信息，确定重新解析?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.analysisSqlImpl()
        }).catch(() => { })
      }else{
        this.analysisSqlImpl()
      }
    },
    /** 解析sql实现 */
    analysisSqlImpl(){
      if(!this.dataForm.sqlText){
        this.$message.error('请先填写sql后再点击解析按钮')
        return
      }
      this.dataListLoading = true
      const params_ = {
        dsId: this.dataForm.dsId,
        sqlText: encryptDes(this.dataForm.sqlText),
        dmType: this.dataForm.dmType
      }
      analysisSql(params_).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataForm.columns = data.data
        } else {
          this.dataForm.columns = []
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.previewList = []
        this.dataListLoading = false
      })
    },
    /** 删除字段信息 */
    deleteHandle(scope){
      this.$confirm(`确定对模型字段[${scope.row.columnName}]进行删除操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataForm.columns.splice(scope.$index, 1)
      }).catch(() => { })
    },
    /** 获取分组信息 */
    getCategoryOptions () {
      getAllList().then(({ data }) => {
        if (data && data.code === 0) {
          const list = data.data
          this.categoryOptions = list && list.length > 0 ? treeDataTranslate(list, 'id', 'parentId') : [];
        } else {
          this.categoryOptions = []
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {})
    },
    /** 获取数据源信息 */
    getDsOptions () {
      getAllDsList().then(({ data }) => {
        if (data && data.code === 0) {
          this.dsOptions = data.data
        } else {
          this.dsOptions = []
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {})
    },
    /** 级联选择器内容过滤，忽略大小写 */
    filterNode(node, keyword){
      if (!keyword) return true;
      let v = keyword.toLowerCase()
      let d = node.data.name.toLowerCase()
      return d.indexOf(v) !== -1;
    },
    /** 切换tab时判断是否加载预览数据 */
    handleClick(tab) {
      // 不是数据预览或者已经有数据时，切换标签不重新加载预览数据
      if(tab.name != 'datePreview' || (this.previewList && this.previewList.length > 0)){
        return
      }
      let dmType = this.dataForm.dmType
      let columns = this.dataForm.columns
      let sqlText = this.dataForm.sqlText
      if(dmType == 'table' || dmType == 'view'){
        if(columns && columns.length > 0){
          this.getPreviewList()
        }
      }else if(dmType == 'sql'){
        if(sqlText && sqlText.length > 0 && columns && columns.length > 0){
          this.getPreviewList()
        }
      }
    },
    /** 获取预览数据 */
    getPreviewList(){
      let dmType = this.dataForm.dmType
      let columns = this.dataForm.columns
      let sqlText = this.dataForm.sqlText
      if(dmType == 'table' || dmType == 'view'){
        if(!columns || columns.length == 0){
          this.$message.error("模型字段配置为空，无法预览数据")
          return
        }
      }else if(dmType == 'sql'){
        if(!sqlText || sqlText.length == 0 || !columns || columns.length == 0){
          this.$message.error("模型sql或模型字段配置为空，请配置sql并解析字段后再进行数据预览")
          return
        }
      }

      this.previewListLoading = true
      const params_ = JSON.parse(JSON.stringify(this.dataForm))
      // 修改时，如果没有修改categoryId则该属性为字符串无需提取选择的id
      params_.categoryId = typeof params_.categoryId == 'string' ? params_.categoryId : params_.categoryId[params_.categoryId.length-1]
      this.$set(params_, 'page', this.pageIndex);
      this.$set(params_, 'limit', this.pageSize);
      getPreviewList(params_).then(({ data }) => {
        if (data && data.code === 0) {
          this.previewList = data.data.list
          this.totalCount = data.data.totalCount
        } else {
          this.previewList = []
          this.totalCount = 0
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.previewListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getPreviewList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getPreviewList()
    },
    /** 表格预览数据展示，主要用于oracle数据库查询结果字段大写展示数据为空 */
    showColInfo(label, data){
      if(!data.hasOwnProperty.call(label)){
        label = label.toUpperCase()
      }
      return data[label]
    }
  }
}
</script>
